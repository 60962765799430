@media(min-width: 600px) {
    .Footer {
        padding: 20px 50px;
    }
}

@media(max-width: 599px) {
    .Footer {
        padding: 20px 20px;
        
    }
}

.Footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;

    background-color: #202020;
}

.footer-copyright {
    margin: 0;

    text-align: left;
    color: white;
    font-family: var(--body-font);
    font-size: 14px;
}