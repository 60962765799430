@media(min-width: 1100px) {
    .hero-image-mobile {
        display: none;
    }
    
    .hero-image-desktop {
        height: 100vh;
        background-size: cover;
        -webkit-background-size: cover;
    }
}

@media(max-width: 1099px) and (min-width: 700px) {
    .hero-image-mobile {
        display: none;
    }
    
    .hero-image-desktop {
        height: 60vw;
        background-size: contain;
        -webkit-background-size: contain;
    }
}

@media(max-width: 699px) {
    .hero-image-mobile {
        
    }

    .hero-image-desktop {
        display: none;
    }
}

.Hero {
    position: relative;
    overflow: hidden;
}

.hero-image-desktop {
    position: relative;
    width: 100%;

    background-image: url('/src/images/hero-image.png');
    background-attachment: fixed;
    background-position-x: center;
    background-repeat: no-repeat;
    -webkit-background-image: url('/src/images/hero-image.png');
    -webkit-background-attachment: fixed;
    -webkit-background-position-x: center;
    -webkit-background-repeat: no-repeat;
}

.hero-image-mobile {
    position: relative;
    width: 100%;
    height: 300px;
    transform: scale(1.2);

    object-fit: cover;
    object-position: center;
}

.hero-filter {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(to bottom, transparent, black);
}

@keyframes fadeInTitle {
    100%{opacity: 1; letter-spacing: 1vw;}
}