@media(min-width: 700px) {
    .Video {
        width: 560px;
    }
}

@media(max-width: 699px) {
    .Video {
        width: 80%;
    }
}

.Video {
    position: relative;
    display: flex;
    aspect-ratio: 16/9;
    margin: 50px 0;
    overflow: hidden;

    border-radius: 5px;
    border: 5px solid var(--velvet);
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    outline-width: 3px;
    outline-style: solid;
    outline-offset: 8px;
}

.Video > iframe {
    position: relative;
    width: 100%;
    height: 100%;
}

.video-unavailable {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 560px; 
    height: 315px;

    background-color: white;
}

.video-unavailable > p {
    margin: 0;

    font-size: 32px;
    font-family: var(--title-font);
}