@media(min-width: 1201px) {
    .AboutRow {
        padding: 20px 15vw;
    }
    
    .about-row-img {
        min-width: 300px;
    }
}

@media(max-width: 1200px) and (min-width: 800px) {
    .AboutRow {
        flex-direction: column;
    }
    
    .about-row-img {
        width: 50%;
    }
}


@media(max-width: 799px) {
    .AboutRow {
        flex-direction: column;
    }

    .about-row-img {
        width: 80%;
    }
}

.About {
    min-height: 100vh;
}

.AboutRow {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 50px 0;
}

.about-row-img {
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: top;


    border-radius: 5px;
    border: 5px solid var(--velvet);
    outline: 4px solid #202020;
    outline-offset: 4px;
}

.about-row-member {
    position: relative;
    padding:  0 30px;
    box-sizing: border-box;
}

.about-row-member-name {
    margin: 30px 0 10px;

    font-size: 42px;
    font-weight: 300;
    font-family: var(--body-font);
}

.about-row-member-part {
    margin: 0;
    font-size: 28px;
    font-family: var(--title-font);
    color: var(--velvet);
}

.about-row-member-divider {
    position: relative;
    margin: 30px 0 10px;
    width: 100px;
    height: 8px;

    background-color: #202020;
}

.about-row-bio {
    margin: 0;
    font-weight: 400;
    font-family: var(--body-font);
}