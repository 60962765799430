@media(min-width: 1000px) {
    .navbar-link {
        opacity: 0;
        animation: fadeIn 1s ease forwards;
    }
}

@media(max-width: 999px) {
    .navbar-link-post {
        display: none;
    }
}

.Navbar {
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 5%;
    padding: 10px 0;
    z-index: 200;

    background-color: white;
}

.navbar-logo {
    width: 56px;
    height: 56px;
}

.navbar-link {
    position: relative;
    overflow-y: hidden;
    padding: 10px;
    color: black;
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
}

.navbar-link > p {
    margin: 0;

    white-space: nowrap;
    font-family: var(--body-font);
    transition: all 300ms ease;
}

.navbar-link-prev {

}

.navbar-link-post {
    position: absolute;
    top: 50px;
    color: var(--velvet);
}

.navbar-socials {
    position: absolute;
    right: 0;
    padding-right: 5%;
}

@keyframes fadeIn {
    100%{opacity: 1; transform: translateX(0);}
}