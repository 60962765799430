@media(min-width: 1100px) {
    .listen-main-video {
        width: 40%;
    }

    .ListenRow {
        gap: 10%;
        padding: 50px 10%;
    }

    .listrow-video, .listrow-details {
        width: 100%;
    }

    .listen-title, .listen-sub-title {
        font-size: 36px;
    }
}

@media(max-width: 1099px) and (min-width: 700px) {
    .listen-main-video {
        width: 70%;
    }

    .ListenRow {
        gap: 50px;
        flex-direction: column !important;
    }

    .listrow-video, .listrow-details {
        box-sizing: border-box;
        width: 80%;
        justify-content: center !important;
    }

    .listenrow-container {
        gap: 100px;
    }

    .listen-title, .listen-sub-title {
        font-size: 24px;
        text-align: center;
    }
}

@media(max-width: 699px) {
    .listen-main-video {
        width: 90%;
    }

    .ListenRow {
        gap: 50px;
        flex-direction: column !important;
    }

    .listrow-video, .listrow-details {
        box-sizing: border-box;
        width: 80%;
        justify-content: center !important;
    }

    .listenrow-container {
        gap: 100px;
    }

    .listen-title, .listen-sub-title {
        font-size: 24px;
        text-align: center;
    }
}

.Listen {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.listen-title, .listen-sub-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 10px;

    font-family: var(--body-font);
    font-weight: 300;
}

.listen-title::before, .listen-sub-title::before, .listen-title::after, .listen-sub-title::after {
    content: '';
    width: 0;
    height: 2px;

    background-color: var(--velvet);
    animation: lineAppear 1s ease forwards;
}

.listen-title {
    margin: 50px 20px 50px;

}

.listen-sub-title {
    margin: 100px 20px 60px;
}

.listen-main-video {
    position: relative;
    aspect-ratio: 16/9;
    margin: 0 0 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.5);
    background-color: var(--velvet);
}

.listen-main-video > p {
    color: white;
    font-family: var(--title-font);
    font-size: 48px;
}

.listen-main-video > iframe {
    position: relative;
    width: 100%;
    height: 100%;

}

.listenrow-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 100px;
}

.ListenRow {
    position: relative;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.listrow-video, .listrow-details {
    position: relative;
    display: flex;
}


.listrow-video > iframe {
    position: relative;
    aspect-ratio: 16/9;
    width: 560px;
    
    border-radius: 5px;
    border: 5px solid var(--velvet);
    outline: 5px solid black;
    outline-offset: 5px;
}

.listrow-details {
    flex-direction: column;
}

.listrow-details > h2, .listrow-details > h4, .listrow-details > p {
    margin: 0;

    font-family: var(--body-font);
}

.listrow-details > h2 {
    margin: 0 0 10px;

    font-weight: 400;
    font-size: 36px;
    color: var(--velvet);
}

.listrow-details > h4 {    
    font-weight: 500;
    color: rgb(80,80,80);
}

.listrow-divider {
    position: relative;
    margin: 40px 0 10px;
    width: 100px;
    height: 10px;

    background-color: #202020;
}

.listrow-details > p {
    line-height: 25px;
}

@keyframes lineAppear {
    100%{width: 12%}
}