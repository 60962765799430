.Socials {
    position: relative;
    display: flex;
    gap: 10px;
    width: fit-content;
    height: 24px;
}

.socials-img {
    height: 24px;
}

a {
    transition: all 300ms ease;
}

a:hover {
    transform: scale(1.1);
}