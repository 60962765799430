@media(min-width: 700px) {
    .ContactForm {
        width: 50%;
    }

    .contact-desc {
        padding: 0 50px;
    }
}

@media(max-width: 699px) {
    .ContactForm {
        width: 80%;
    }

    .contact-desc {
        text-align: center;
        width: 80%;
    }
}

.Contact {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}

.contact-advertisement {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 50px;
}

.contact-advertisement > h1 {
    text-align: center;
    margin: 30px 0;

    font-size: 48px;
    font-weight: 300;
    font-family: var(--body-font);
    color: #202020;
}

.contact-advertisement-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contact-advertisement-wrapper > p {
    text-align: center;
    width: fit-content;

    font-family: var(--body-font);
    font-weight: 500;
    font-size: 18px;
    color: #202020;
    animation: adHighlight 10s ease infinite;
}

.contact-advertisement-wrapper > p:nth-child(2) {
    animation-delay: 2s;
}

.contact-advertisement-wrapper > p:nth-child(3) {
    animation-delay: 4s
}

.contact-advertisement-wrapper > p:nth-child(4) {
    animation-delay: 6s
}

.contact-advertisement-wrapper > p:nth-child(5) {
    animation-delay: 8s
}

.contact-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 80px);
    padding-bottom: 50px;
    box-sizing: border-box;
}

.contact-title {
    margin: 50px 0;
    position: relative;

    font-family: var(--body-font);
    font-weight: 300;
    font-size: 48px;
}

.contact-desc {
    margin: 0 0 50px;
    box-sizing: border-box;

    color: var(--velvet);
    font-family: var(--body-font);
    font-weight: 500;
    font-size: 18px;
}

.ContactForm {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px;
    box-sizing: border-box;

    transition: all 500ms ease;
}

.ContactForm > label {
    margin-bottom: 10px;
    text-align: left;

    font-weight: 500;
    font-family: var(--body-font);
}

.ContactForm > input:not([type="submit"]) {
    margin-bottom: 30px;
    padding: 10px;

    border-radius: 3px;
    border: 2px solid var(--velvet);
    outline: none;
    font-family: var(--body-font);
    font-size: 14px;
    font-weight: 500;
    transition: all 200ms ease;
}

.ContactForm > input[type="submit"] {
    position: relative;
    width: 150px;
    height: 35px;
    align-self: center;
    margin: 50px 0 0;

    font-family: var(--body-font);
    border: none;
    background-color: var(--velvet);
    color: white;
    border-radius: 5px;
    transition: all 200ms ease;
    outline: 3px solid var(--velvet);
    outline-offset: -3px;
}

.ContactForm > input[type="submit"]:hover {
    cursor: pointer;
    background-color: #202020;
    outline-offset: 3px;
}

.ContactForm > textarea {
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    resize: vertical;

    border-radius: 3px;
    border: 2px solid var(--velvet);
    min-height: 200px;
    max-height: 300px;
    font-family: var(--body-font);
    font-size: 14px;
    font-weight: 500;
    outline: none;
    transition: all 200ms ease;
}

.contact-recaptcha {
    margin-top: 50px;
    align-self: center;
}

.input-empty {
    outline: 2px solid rgb(255,100,100) !important;
    outline-offset: 3px;
}

.warning {
    padding-top: 50px;
    text-align: center;
    font-family: var(--body-font);
    font-weight: 600;
    color: rgb(255,100,100);
}

.success {
    padding: 20px;
    box-sizing: border-box;
}

.success-title {
    padding-top: 50px;
    text-align: center;
    font-family: var(--body-font);
    font-weight: 300;
    font-size: 32px;
    color: rgb(0, 151, 38);
}

.fail-title {
    padding-top: 50px;
    text-align: center;
    font-family: var(--body-font);
    font-weight: 300;
    font-size: 32px;
    color: rgb(255,100,100);
}

.success-desc, .fail-desc {
    text-align: center;

    font-family: var(--body-font);
    font-weight: 500;
    color: var(#202020);
}

.fail-desc > a {
    color: var(--velvet);
}

.form-complete {
    opacity: 0;
}

@keyframes adHighlight {
    0% {}
    10% {transform: scale(1.2); color: var(--velvet);}
    20% {transform: scale(1); color: #202020;}
    100% {}
}