:root {
  --title-font: "Dancing Script", cursive;
  --body-font: "Montserrat", sans-serif;
  --velvet: #7B0B1B;
}

@media(min-width: 1200px) {
  .app-text {
    width: 40%;
  }

  .app-container {
    padding: 50px 0;
  }

  .app-socials {
    flex-direction: row;
  }

  .app-socials-left, .app-socials-right {
    width: 50%;
  }
}

@media(max-width: 1199px) {
  .app-text {
    width: 70%;
  }

  .app-container {
    padding: 0px 0 50px;
  }

  .app-socials {
    flex-direction: column;
  }

  .app-socials-title {
    margin-top: 100px;
  }

  .app-socials-left, .app-socials-right {
    width: 80%;
  }
}

.App {
  text-align: center;
  align-items: center;
  background-color: black;
}

.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  background-color: black;
}

.app-title {
  margin: 50px 0;

  color: white;
  font-size: 56px;
  font-weight: 100;
  font-family: var(--body-font);
}

.app-title > strong {
  font-family: var(--title-font);
  font-size: 84px;
  filter: drop-shadow(0 0 5px var(--velvet));
}

.app-text {
  margin-top: 50px;
  text-align: left;
  line-height: 50px;

  color: white;
  font-size: 24px;
  font-weight: 200;
  font-family: var(--body-font);
}

.app-text > strong {
  font-weight: 100;
  font-size: 64px;
  font-family: var(--title-font);
  filter: drop-shadow(0 0 5px var(--velvet));
}

.app-socials, .app-contact {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.app-socials {
  position: relative;
  display: flex;
  padding: 100px 0 0;
  
  background-color: white;
}

.app-socials-left, .app-socials-right {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.app-socials-right {
  flex-direction: column;
}

.app-socials-title {
  font-family: var(--body-font);
  font-weight: 300;
  font-size: 48px;
}

.app-socials-email {
  margin: 100px 0 0;

  line-height: 40px;
  font-family: var(--body-font);
  font-weight: 400;
  font-size: 20px;
}

.app-socials-email > a {
  color: var(--velvet);
  font-weight: 700;
  text-decoration: none;
  transition: all 300ms ease;
}

.app-socials-email > a:hover {
  cursor: pointer;
  color: #202020;
}

.app-or-title {
  margin: 0;
  padding: 120px 0 50px;

  font-family: var(--body-font);
  font-weight: 400;
  font-size: 48px;
  color: #202020;
  background-color: white;
}

.app-contact {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 0 100px;

  background-color: white;
}

.app-contact-title {
  margin: 50px 0;

  font-family: var(--body-font);
  font-weight: 300;
  font-size: 36px;
}