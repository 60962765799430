@media(min-width: 1200px) {
    .construction-title {
        font-size: 8vw;
    }
}

@media(max-width: 1199px) {
    .construction-title {
        font-size: 12vw;
    }
}

.Construction {
    position: relative;
    width: 100%;
    min-height: 100vh;

    background-color: var(--velvet);
}

.construction-container {
    padding: 0 10vw;
}

.construction-title {
    position: relative;

    font-family: var(--body-font);
    font-weight: 200;
    color: white;
}

.construction-desc {
    position: relative;
    line-height: 40px;
    margin: 50px 0;

    color: white;
    font-family: var(--body-font);
    font-size: 24px;
    font-weight: 300;
}

.construction-desc > a, .construction-desc > a:visited {
    color: black;
    font-weight: 600;
}

.construction-desc > a:hover {
    color: white;
}

.construction-return-home {
    position: relative;
    padding: 12px 20px;

    border-radius: 5px;
    background-color: white;
    color: black;
    text-decoration: none;
    font-family: var(--body-font);
    font-weight: 600;
}

.construction-return-home:hover {
    background-color: var(--velvet);
    color: white;
}