.NavbarMobile {
    position: sticky;
    top: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 5%;
    padding: 10px 0;
    z-index: 200;
    text-align: center;

    background-color: white;
}

.navbar-logo {
    width: 56px;
    height: 56px;
}

.navbar-mobile-menu-btn {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 50%;
    right: 25px;
    width: 25px;
    height: 25px;
    transform: translateY(-50%);

    cursor: pointer;
}

.navbar-mobile-menu-btn-line {
    position: absolute;
    width: 25px;
    height: 3px;

    border-radius: 5px;
    background-color: black;
    transition: all 200ms ease;
}

@media (min-width: 700px) {
    .navbar-link {
        font-size: 16px;
    }
}

@media (max-width: 699px) {
    .navbar-link {
        font-size: 18px;
    }
}

.navbar-link {
    user-select: none;
}
    
.menu-top-active {
    transform: rotate(45deg) !important;
}

.menu-middle-active {
    opacity: 0;
}

.menu-bottom-active {
    transform: rotate(-45deg) !important;
}

.navbar-mobile-menu-options-container {
    margin: 0 0 50px;
}

.navbar-mobile-menu-options {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 80px;
    width: 100%;
    height: calc(100vh - 80px);
    padding-top: 50px;

    background-color: white;
}