@media(min-width: 1000px) {
    .Events {
        padding: 50px;
        gap: 50px;
    }

    .Event {
        min-height: 300px;
        width: 75%;
    }

    .event-information {
        border-radius: 0 10px 10px 0;
    }
    
    .Event > iframe {
        width: 300px;
    }
}

@media(max-width: 999px) {
    .Events {
        padding: 30px;
        gap: 30px;
        justify-content: center;
    }

    .Event {
        flex-direction: column;
        width: 90%;
    }
    
    .Event > iframe {
        width: 100%;
    }
}

.event-wrapper {
    height: 100vh;
}

.Events {
    position: relative;
    display: flex;
    flex-flow: wrap;
    align-items: stretch;
}

.Event {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 400px;

    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.Event > iframe {
    min-height: 300px;
    width: 100%;
}

.event-information {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: var(--velvet);
}

.event-title,
.event-date,
.event-street,
.event-state,
.event-desc,
.event-more-info  {
    font-family: var(--body-font);
}


.event-title {
    margin: 0;

    font-size: 24px;
    font-weight: 300;
    color: white;
}

.event-date {
    margin: 0;

    font-size: 14px;
    font-weight: 500;
    color: rgba(255,255,255,0.75);
}

.event-address {
    text-decoration: none;
    opacity: 0.75;
}

.event-address:hover {
    transform: none;
    opacity: 1;
}

.event-street {
    margin: 10px 0 0;
    color: white;
    font-weight: 400;
}

.event-state {
    margin: 0;
    color: white;
    font-weight: 400;
}

.event-divider {
    position: relative;
    width: 100px;
    height: 8px;
    margin: 20px 0 0;

    background-color: #202020;
}

.event-desc {
    margin: 5px 0 0;
    font-weight: 400;
    font-size: 14px;
    color: white;
}

.event-more-info {
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    width: fit-content;
    margin: 10px 0 0;

    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    color: #202020;
    background-color: white;
}

.event-more-info:hover {
    transform: none;

    background-color: #202020;
    color: white;
}